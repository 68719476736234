import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ErrorHandlerSVG from "Components/UI/Images/ErrorHandler.svg";
import { deleteAllCookies } from "Components/Assets/UIServices";
import { MdExpandMore } from "react-icons/md";

function ErrorHandler({ error, resetErrorBoundary }) {
  useEffect(() => {
    // deleteAllCookies();
  }, []);

  const reload = async () => {
    await deleteAllCookies();
    await resetErrorBoundary();
  };

  // Helper function to format the error object
  const formatError = (error) => {
    if (typeof error === "object" && error !== null) {
      const { message, stack, ...rest } = error;
      return JSON.stringify({ message, stack, ...rest }, null, 2);
    }
    return String(error);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      minHeight="98vh"
      maxHeight="auto"
      sx={{
        textAlign: { xs: "center", sm: "left" }, // Centered for xs (extra small) screens, left aligned for larger screens
        px: { xs: 2, sm: 0 }, // Padding on extra small screens but not on larger screens
        py: 1,
        backgroundColor: "#e7deda",
      }}
    >
      <Box mb={2}>
        <img src={ErrorHandlerSVG} alt="Error" style={{ width: "50vh" }} />
      </Box>
      <Box>
        <Typography variant="h6" align="center">
          Oops! Some error occurred. <br />
          Please reload the page and try to login again.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button variant="contained" onClick={reload}>
          Reload
        </Button>
      </Box>
      <Box width={{ xs: "90%", sm: "70%", md: "50%", lg: "40%" }} mt={2}>
        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography>Error details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              component="pre"
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
            >
              {formatError(error)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default ErrorHandler;
