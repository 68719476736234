import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import ImageUpload from "Components/Assets/ReusableComp/EditImage/ImageUpload";
import {
  useEditProfileStore,
  useSnackbarStore,
} from "Components/Assets/StateManagement";
import { getCookie } from "Components/Assets/UIServices";
import {
  FullScreenModalContainer,
  FullScreenModalContent,
  FullScreenModalHeader,
} from "Components/UI/GlobalStyles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";

function EditBanner({ image }) {
  const isEditProfileBanner = useEditProfileStore(
    (state) => state.isEditProfileBanner
  );
  const setIsEditProfileBanner = useEditProfileStore(
    (state) => state.setIsEditProfileBanner
  );
  // Accessing alert snackbar data from global state
  const setShowSnackbar = useSnackbarStore((state) => state.setShowSnackbar);
  const setSnackbarMessage = useSnackbarStore(
    (state) => state.setSnackbarMessage
  );
  const setSnackbarType = useSnackbarStore((state) => state.setSnackbarType);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImageType, setBannerImageType] = useState("");

  const getProfileBannerUrl =
    process.env.REACT_APP_BASE_URL_TEST_BACKEND +
    process.env.REACT_APP_EDIT_PROFILE_BANNER;
  const API_KEY = process.env.REACT_APP_API_KEY;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const requestBody = {
        id: getCookie("uid"),
        image: bannerImage,
      };
      const requestHeader = {
        "X-API-Key": API_KEY,
      };
      const resp = await axios.post(getProfileBannerUrl, requestBody, {
        headers: requestHeader,
      });
      await setShowSnackbar(true);
      await setSnackbarType("success");
      await setSnackbarMessage(resp.data.message);
      setIsEditProfileBanner();
      setBannerImage(null);
    } catch (error) {
      setShowSnackbar(true);
      setSnackbarType("error");
      setSnackbarMessage("Profile Banner not updated!");
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (image) {
      if (image.length === 0) setBannerImageType("add");
      else {
        setBannerImage(image);
        setBannerImageType("edit");
      }
    }
  }, [image]);
  return (
    <Modal open={isEditProfileBanner} sx={FullScreenModalContainer}>
      <Box>
        <Box sx={FullScreenModalHeader}>
          <Typography variant="h6">Edit Profile Banner</Typography>

          <IconButton
            aria-label="close"
            onClick={() => setIsEditProfileBanner()}
            sx={{
              position: "absolute",
              right: 10,
              top: 5,
              color: (theme) => theme.palette.grey[500],
              display: "flex",
              alignSelf: "center",
            }}
          >
            <MdClose />
          </IconButton>
        </Box>
        <Box sx={FullScreenModalContent}>
          <Box display={"flex"} justifyContent={"center"}>
            <ImageUpload
              image={bannerImage}
              setImage={setBannerImage}
              type={bannerImageType}
            />
          </Box>
          {/* button */}
          <Box mt={4}>
            <>
              {/* Horizontal line for visual separation */}
              <hr />
              {/* Container for the Update buttons */}
              <Box>
                {isSubmitting ? (
                  // Display progress indicator while submitting
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      float: "right",
                      position: "relative",
                    }}
                  >
                    Updating...
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "var(--header-nav-text)",
                        ml: 2,
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      float: "right",
                      position: "relative",
                    }}
                    // Click event handler to handle the "Update" action
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Save Image
                  </Button>
                )}
              </Box>
            </>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default EditBanner;
