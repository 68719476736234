import {
  topMerchants,
  topProducts,
  customers,
  customCharges,
  orderTypes,
  notificationEvents,
  reviews,
  commissionReport,
} from "Components/Assets/DummyData";
import dayjs from "dayjs";
import { event } from "jquery";

// Function 'updateOrderCount' to update the counts of orders for each status in 'orderCount'
export const updateOrderCount = (orders) => {
  // Object 'orderCount' to store the counts of orders for different statuses
  let orderCount = {
    All: orders.length, // Initial count of orders is set to the total number of orders
    Pending: 0, // Initial count of pending orders is set to 0
    Accepted: 0, // Initial count of accepted orders is set to 0
    Ready: 0, // Initial count of ready orders is set to 0
    Collected: 0, // Initial count of collected orders is set to 0
    Completed: 0, // Initial count of completed orders is set to 0
    Cancelled: 0, // Initial count of cancelled orders is set to 0
    PayFailed: 0, // Initial count of PayFailed orders is set to 0
    PaySuccess: 0, // Initial count of PaySuccess orders is set to 0
  };
  // Loop through each order in 'orders' array
  for (const order of orders) {
    // Get the status of the current order
    const key = order.status;
    // Increment the corresponding order count in 'orderCount'
    orderCount[key]++;
  }
  // Return the updated 'orderCount' object
  return orderCount;
};

export const getCustomers = (custType) => {
  if (custType === "All") return customers;
  else return customers.filter((cust) => cust.status === custType);
};

export const updateCustomersCount = (customers) => {
  let customersCount = {
    All: customers.length,
    Active: 0,
    Block: 0,
  };

  for (const customer of customers) {
    const key = customer.status;
    customersCount[key]++;
  }
  return customersCount;
};

export const getToday = () => {
  return new Date() >= new Date("2024-07-01");
};

export const getTopMerchants = () => {
  return topMerchants;
};
export const getTopProducts = () => {
  return topProducts;
};
export const getCustomCharges = () => {
  return customCharges;
};

export const getOrderTypes = () => {
  return orderTypes;
};

export const getNotificationEvents = () => {
  return notificationEvents;
};

export const getReviews = () => {
  return reviews;
};
export const getCommissionReport = () => {
  return commissionReport;
};

export const endcodePermissionJson = async (data) => {
  const permissionsJson = data.map((permission) => JSON.stringify(permission));
  const joinedPermissions = permissionsJson.join(";");
  const encodedPermissions = encodeURIComponent(joinedPermissions);
  return encodedPermissions;
};

//global set cookies
//@param {cookieName , cookieValue , expairydays}
export const setCookie = async (name, value, days) => {
  if (days === undefined) days = 1;
  const expires = new Date();
  //expire after number of days--prod
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  //expire after number of min--test
  // expires.setTime(expires.getTime() + 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

//global set multiple cookies
export const setCookies = (cookieString) => {
  // Split the cookie string into individual cookies using regex to respect JSON structure
  var cookies = cookieString.match(/(?:[^{;]+|\{[^}]+\})+/g);

  // Iterate over each cookie
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim(); // Trim any leading or trailing spaces
    var cookieParts = cookie.split("="); // Split into key-value pairs
    // Ensure the cookie has a key and value
    if (cookieParts.length > 1) {
      var cookieName = decodeURIComponent(cookieParts[0].trim()); // Decode and trim the cookie name
      var cookieValue = decodeURIComponent(
        cookieParts.slice(1).join("=").trim()
      ); // Decode and re-join the cookie value
      // Set the cookies
      if (cookieName.toLowerCase() !== "permissions")
        document.cookie = cookieName + "=" + cookieValue + "; path=/";
      // Ensure path is specified
      else setCookie(cookieName, cookieParts.slice(1).join("=").trim(), 1);
    }
  }
};
//global delete multiple cookies
export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
  window.location.reload();
};

//global get cookies
export const getCookie = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === cookieName) {
      return value;
    }
  }
  return null;
};
//delete cookie
export const deleteCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

//copy cookies and return
export const copyCookies = () => {
  let storedCookies = "";

  // Loop through each cookie and store it temporarily
  document.cookie.split(";").forEach((cookie) => {
    storedCookies += cookie + ";";
  });
  return storedCookies;
};

//check for permissions of the user
export const hasPermissions = (encodedPermissions, category, action) => {
  // Decode and split the stored data
  const decodedPermissions = decodeURIComponent(encodedPermissions);
  const permissionStrings = decodedPermissions.split(";");

  // Parse each string back into an object
  const permissions = permissionStrings.map((permissionString) =>
    JSON.parse(permissionString)
  );
  return permissions.some(
    (permission) =>
      permission.category.toLowerCase() === category.toLowerCase() &&
      (action
        ? permission.actions.some(
            (pAction) => pAction.toLowerCase() === action.toLowerCase()
          )
        : true)
  );
};

//Modify JSON keeping old values intact
export const modifyObject = async (originalObject, modifiedValues) => {
  const mergeDeep = (target, source) => {
    for (const key of Object.keys(source)) {
      if (
        source[key] instanceof Object &&
        !Array.isArray(source[key]) &&
        key in target
      ) {
        target[key] = mergeDeep(target[key], source[key]);
      } else if (Array.isArray(source[key])) {
        target[key] = source[key]; // Replace array to handle deletions
      } else {
        target[key] = source[key]; // Update primitive values or objects directly
      }
    }
    return target;
  };

  return mergeDeep({ ...originalObject }, modifiedValues);
};

//Table Component update value
export const updateDataInTable = async (dataList, updates, id) => {
  // Find the index of the array list with the given ID
  const index = dataList.findIndex((data) => data.id === id);
  // If data with the given ID is found
  if (index !== -1) {
    // Update the data with the new key-value pairs
    dataList[index] = {
      ...dataList[index],
      ...updates,
    };
  } else {
    console.log("Mentioned ID not found.");
  }
};

//Table Component Delete data
export const deleteDataFromTable = async (dataList, id) => {
  const updated = dataList.filter((obj) => obj.id !== id);
  return updated;
};

//Day JS date formatter
export const getFormattedDate = (dateString) => {
  return dayjs(dateString).format("ddd DD MMM, hh:mm A");
};

//Day JS time formatter
export const getFormattedTime = (date) => {
  return dayjs(date).format("HH:mm");
};

export const parseTimeStringToDate = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};

const targetArray = [
  [
    [0, 0],
    [3, 6],
    [6, 1],
    [0, 0],
  ],
];

/*For sending the data to DB -> Array of Arrays*/
export const convertMapsGeofenceCoords = (data) => {
  // Check if data is an empty array
  if (data.length === 0) {
    return targetArray;
  }

  // Ensure data is wrapped in an array if it's not already
  data = [data];

  // If there's more than one item in the array, use only the last one
  if (data.length > 1) {
    data = [data[data.length - 1]];
  }

  return data.map((polygon) => {
    const convertedPolygon = polygon.map((point) => [point.lng, point.lat]);
    // Add the first element to the end of the polygon to close it
    convertedPolygon.push(convertedPolygon[0]);
    return convertedPolygon;
  });
};

/** For retrieving the data from DB -> Array of JSONS  */
export const reverseMapsGeofenceCoords = (data) => {
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
        if (!arraysEqual(arr1[i], arr2[i])) return false;
      } else if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };
  const shouldClearPolygons = arraysEqual(data, targetArray);

  if (shouldClearPolygons) {
    return [];
  }

  return data.map((subArray) =>
    subArray.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }))
  );
};
