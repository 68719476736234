import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DrawerHeader, drawerWidth } from "Components/UI/GlobalStyles";
import { MdExpandLess, MdExpandMore, MdSpaceDashboard } from "react-icons/md";
import { FaStar } from "react-icons/fa6";
import {
  BiSolidPackage,
  BiSolidStarHalf,
  BiSolidShoppingBags,
} from "react-icons/bi";
import { PiSealPercentFill } from "react-icons/pi";
import { BsPeopleFill, BsGraphUpArrow } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAccountStore,
  useNavigationStore,
} from "Components/Assets/StateManagement";
import {
  Chip,
  Collapse,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { VscGraphLine } from "react-icons/vsc";
import { TbReport } from "react-icons/tb";
import { theme } from "Components/UI/themes";
import { getCookie, hasPermissions } from "Components/Assets/UIServices";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.down("sm")]: {
    width: 0,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "isDrawerOpen",
})(({ theme, isDrawerOpen }) => ({
  width: drawerWidth,
  // position: "fixed",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  // zIndex: 999,
  ...(isDrawerOpen && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!isDrawerOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavIcon = styled(ListItemIcon)(({ theme, isDrawerOpen }) => ({
  minWidth: 0,
  justifyContent: "center",
  color: "whitesmoke",
}));

const M = process.env.REACT_APP_LOW_LEVEL;
const SA = process.env.REACT_APP_TOP_LEVEL;

export default function SideNav() {
  const navigate = useNavigate();
  const isDrawerOpen = useNavigationStore((state) => state.isDrawerOpen);
  const setDrawerOpen = useNavigationStore((state) => state.setDrawerOpen);
  const isAcceptingOrders = useNavigationStore(
    (state) => state.isAcceptingOrders
  );
  const setAcceptingOrders = useNavigationStore(
    (state) => state.setAcceptingOrders
  );

  const location = useLocation();

  // Check if the screen size is "sm" (600px) or smaller
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [analyticsOpen, setAnalyticsOpen] = React.useState(false);

  // const userPermission = useAccountStore((state) => state.userData.permissions);
  const userPermission = getCookie("permissions");

  return (
    <>
      <CssBaseline />
      <Drawer variant="permanent" isDrawerOpen={isDrawerOpen}>
        <DrawerHeader />
        <List>
          {getCookie("role") === M ? (
            <>
              <ListItem sx={{ display: "block" }}>
                <ListItemText sx={{ opacity: isDrawerOpen ? 1 : 0 }}>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    fontSize={15}
                    letterSpacing={1}
                    sx={{
                      maxWidth: drawerWidth - 10,
                      display: { xs: "none", sm: "flex" },
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {getCookie("firstName") + " " + getCookie("lastName")}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid
                    container
                    alignItems={"center"}
                    spacing={1}
                    item
                    xs={6}
                    sx={{ display: { xs: "none", sm: "flex" } }}
                  >
                    <Grid item>
                      <FaStar style={{ color: "gold" }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">4</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {isAcceptingOrders === "true" ? (
                      <Chip label="Open" color="success" size="small" />
                    ) : (
                      <Chip label="Closed" color="warning" size="small" />
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            </>
          ) : null}
          {/* ===== Dashboard ===== */}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard");
              if (isSmDown) {
                setDrawerOpen();
              }
            }}
            className={location.pathname === "/dashboard" ? "active" : null}
          >
            <Tooltip title="Dashboard" arrow placement="right">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isDrawerOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                  <MdSpaceDashboard />
                </NavIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
          {/* ===== Orders ===== */}
          {hasPermissions(userPermission, "orders") ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/orders");
                if (isSmDown) {
                  setDrawerOpen();
                }
              }}
              className={location.pathname === "/orders" ? "active" : null}
            >
              <Tooltip title="Orders" arrow placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isDrawerOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                    <BiSolidPackage />
                  </NavIcon>
                  <ListItemText
                    primary="Orders"
                    sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ) : null}
          {/* ===== Merchants ===== */}
          {getCookie("role") === M ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/catalogs/category");
                if (isSmDown) {
                  setDrawerOpen();
                }
              }}
              className={
                location.pathname.includes("catalogs") ? "active" : null
              }
            >
              <Tooltip title="Catalogs" arrow placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isDrawerOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                    <BiSolidShoppingBags />
                  </NavIcon>
                  <ListItemText
                    primary="Catalogs"
                    sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ) : hasPermissions(userPermission, "merchants") ? (
            getCookie("role").toLowerCase() === SA.toLowerCase() ? (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  navigate("/merchants");
                  if (isSmDown) {
                    setDrawerOpen();
                  }
                }}
                className={
                  location.pathname.includes("merchants") ? "active" : null
                }
              >
                <Tooltip title="Franchise Admin" arrow placement="right">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: isDrawerOpen ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                      <BiSolidShoppingBags />
                    </NavIcon>
                    <ListItemText
                      primary="Franchise Admin"
                      sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ) : (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  navigate("/merchants");
                  if (isSmDown) {
                    setDrawerOpen();
                  }
                }}
                className={
                  location.pathname.includes("merchants") ? "active" : null
                }
              >
                <Tooltip title="Merchants" arrow placement="right">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: isDrawerOpen ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                      <BiSolidShoppingBags />
                    </NavIcon>
                    <ListItemText
                      primary="Merchants"
                      sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )
          ) : null}
          {/* ===== Customers ===== */}
          {getCookie("role").toLowerCase() === SA.toLowerCase() ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/customers");
                if (isSmDown) {
                  setDrawerOpen();
                }
              }}
              className={location.pathname === "/customers" ? "active" : null}
            >
              <Tooltip title="Customers" arrow placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isDrawerOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                    <BsPeopleFill />
                  </NavIcon>
                  <ListItemText
                    primary="Customers"
                    sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ) : null}
          {/* Promotion / Coupons */}
          {getCookie("role") !== M ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/promotions");
                if (isSmDown) {
                  setDrawerOpen();
                }
              }}
              className={
                location.pathname.includes("promotions") ? "active" : null
              }
            >
              <Tooltip title="Promotions" arrow placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isDrawerOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                    <BsGraphUpArrow />
                  </NavIcon>
                  <ListItemText
                    primary="Promotions"
                    sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ) : (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/coupons");
                if (isSmDown) {
                  setDrawerOpen();
                }
              }}
              className={
                location.pathname.includes("coupons") ? "active" : null
              }
            >
              <Tooltip title="Coupons" arrow placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isDrawerOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                    <PiSealPercentFill />
                  </NavIcon>
                  <ListItemText
                    primary="Coupons"
                    sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}
          {/* ===== Analytics ===== */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <Tooltip title="Analytics" arrow placement="right">
              <ListItemButton
                onClick={() => setAnalyticsOpen(!analyticsOpen)}
                sx={{
                  minHeight: 48,
                  justifyContent: isDrawerOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                  <TbReportAnalytics />
                </NavIcon>
                <ListItemText
                  primary="Analytics"
                  sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                />
                {analyticsOpen ? <MdExpandLess /> : <MdExpandMore />}
              </ListItemButton>
            </Tooltip>
            <Collapse in={analyticsOpen} unmountOnExit>
              <List disablePadding sx={{ cursor: "pointer" }}>
                <Tooltip title="Business Analytics" arrow placement="right">
                  <ListItem
                    sx={{ pl: 4 }}
                    onClick={() => {
                      navigate("/analytics/business");
                      if (isSmDown) {
                        setDrawerOpen();
                      }
                    }}
                    className={
                      location.pathname === "/analytics/business"
                        ? "active"
                        : null
                    }
                  >
                    <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                      <VscGraphLine />
                    </NavIcon>
                    <ListItemText
                      primary="Buisness"
                      sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Review Analytics" arrow placement="right">
                  <ListItem
                    sx={{ pl: 4 }}
                    onClick={() => {
                      navigate("/analytics/review");
                      if (isSmDown) {
                        setDrawerOpen();
                      }
                    }}
                    className={
                      location.pathname === "/analytics/review"
                        ? "active"
                        : null
                    }
                  >
                    <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                      <BiSolidStarHalf />
                    </NavIcon>
                    <ListItemText
                      primary="Review"
                      sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Reports Analytics" arrow placement="right">
                  <ListItem
                    sx={{ pl: 4 }}
                    onClick={() => {
                      navigate("/analytics/reports");
                      if (isSmDown) {
                        setDrawerOpen();
                      }
                    }}
                    className={
                      location.pathname === "/analytics/reports"
                        ? "active"
                        : null
                    }
                  >
                    <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                      <TbReport />
                    </NavIcon>
                    <ListItemText
                      primary="Reports"
                      sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                    />
                  </ListItem>
                </Tooltip>
              </List>
            </Collapse>
          </ListItem>
          {/* ===== Settings ===== */}
          <Tooltip title="Settings" arrow placement="right">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/settings");
                if (isSmDown) {
                  setDrawerOpen();
                }
              }}
              className={location.pathname === "/settings" ? "active" : null}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isDrawerOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <NavIcon sx={{ mr: isDrawerOpen ? 3 : "auto" }}>
                  <AiFillSetting />
                </NavIcon>
                <ListItemText
                  primary="Settings"
                  sx={{ opacity: isDrawerOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>

        <Divider />
      </Drawer>
    </>
  );
}
