//Dashboard
export let topMerchants = [
  {
    id: 1,
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 1,
    imgUri:
      "https://cdn-upload.hyperzod.app/public/1269/images/b4eea2d3-e3b5-4584-b3ff-1f7a74e1c06c.webp",
  },
  {
    id: 2,
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 10,
    imgUri:
      "https://cdn-upload.hyperzod.app/public/1269/images/b4eea2d3-e3b5-4584-b3ff-1f7a74e1c06c.webp",
  },
  {
    id: 3,
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 5,
    imgUri: "",
  },
];

//Dashboard
export let topProducts = [
  {
    id: 1,
    product: "Chicken Dum Biriyani",
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 1,
    imgUri:
      "https://cdn-upload.hyperzod.app/public/1269/images/b4eea2d3-e3b5-4584-b3ff-1f7a74e1c06c.webp",
  },
  {
    id: 2,
    product: "Fried Rice",
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 10,
    imgUri:
      "https://cdn-upload.hyperzod.app/public/1269/images/b4eea2d3-e3b5-4584-b3ff-1f7a74e1c06c.webp",
  },
  {
    id: 3,
    product: "Roll",
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 5,
    imgUri: "",
  },
  {
    id: 4,
    product: "Momo",
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 5,
    imgUri: "",
  },
  {
    id: 5,
    product: "Noodles",
    merchant: "Sherwin's Minimart",
    merchantId: "636ce01e8f6a9f9aa10b0824",
    orders: 5,
    imgUri: "",
  },
];

//Customers Page
export let customers = {
  test: [
    {
      Name: "krishna",
      Email: "krishna@gmail.com",
      Phone: "827422346",
      status: "Active",
    },
    {
      Name: "Aditya",
      Email: "adityak@gmail.com",
      Phone: "764826384",
      status: "Block",
    },
  ],
};

//Settings -> Orders
export let customCharges = [
  {
    id: 1,
    chargeName: "Packaging charges",
    chargeDescription: "charges levied for packaging of food products",
    applicableOn: "Merchant",
    rule: {
      value: "",
      orderType: [],
    },
    status: true,
  },
  {
    id: 2,
    chargeName: "Delivery charges",
    chargeDescription:
      "charges levied for delivery (Within 5km free. 5km+ Rs.10 for each 5 km ",
    applicableOn: "Global",
    rule: {
      value: "10",
      orderType: ["Delivery", "Pickup", "Custom"],
    },
    status: true,
  },
];

//Settings -> Orders
export let orderTypes = [
  {
    id: 1,
    name: "Delivery",
    status: true,
  },
  {
    id: 2,
    name: "Pickup",
    status: true,
  },
  {
    id: 3,
    name: "Custom",
    status: false,
  },
];

export let notificationEvents = [
  {
    id: 1,
    name: "Order Placed",
    description: "When an Order gets placed",
    optionalDescription: "Order has been placed",
    sms: true,
    email: false,
    notification: null,
  },
  {
    id: 2,
    name: "Order Accepted",
    description: "When an Order is processing",
    optionalDescription: "Order is accepted",
    sms: false,
    email: true,
    notification: null,
  },
  {
    id: 3,
    name: "Order Ready",
    description: "When an Order is ready",
    optionalDescription: "Order is ready",
    sms: true,
    email: false,
    notification: null,
  },
  {
    id: 4,
    name: "Order Picked",
    description: "When an Order gets picked",
    optionalDescription: "Order is picked",
    sms: false,
    email: false,
    notification: null,
  },
  {
    id: 5,
    name: "Order Cancelled",
    description: "When an Order gets cancelled",
    optionalDescription: "Order is cancelled",
    sms: false,
    email: true,
    notification: null,
  },
  {
    id: 6,
    name: "Order Completed",
    description: "When an Order gets Completed",
    optionalDescription: "Order is completed",
    sms: true,
    email: true,
    notification: null,
  },
  {
    id: 7,
    name: "User Invitation",
    description: "When an Invitation is sent to a new user",
    optionalDescription: "User Invited",
    sms: true,
    email: false,
    notification: null,
  },
];

export let reviews = [
  {
    orderId: 10987,
    rating: 1.5,
    review: "Bad Food",
    customerName: "Priyom Saha",
    merchantName: "ABC Restaurant",
    dateTime: "Tue 15 Jun 11:59 PM",
  },
  {
    orderId: 187630,
    rating: 4.5,
    review: "Good Food",
    customerName: "Priyom Saha",
    merchantName: "FDE Restaurant",
    dateTime: "Tue 15 Jun 11:59 PM",
  },
  {
    orderId: 187630,
    rating: 5,
    review: "V. Good Food",
    customerName: "Priyom Saha",
    merchantName: "EFG Restaurant",
    dateTime: "Tue 15 Jun 11:59 PM",
  },
];

export let commissionReport = [
  {
    id: 1,
    imgUri:
      "https://images.jdmagicbox.com/comp/hyderabad/i4/040pxx40.xx40.171013211011.y2i4/catalogue/capital-multicuisine-restaurant-malakpet-hyderabad-home-delivery-restaurants-9e7wi.jpg?clr=#661400",
    merchantName: "ABC Restaurant",
    orderCount: 1200,
    total: 1220009,
    commission: 3860,
  },
  {
    id: 2,
    imgUri:
      "https://images.jdmagicbox.com/comp/hyderabad/i4/040pxx40.xx40.171013211011.y2i4/catalogue/capital-multicuisine-restaurant-malakpet-hyderabad-home-delivery-restaurants-9e7wi.jpg?clr=#661400",
    merchantName: "ABC Restaurant",
    orderCount: 1200,
    total: 1220009,
    commission: 3860,
  },
  {
    id: 3,
    imgUri: "",
    merchantName: "ABC Restaurant",
    orderCount: 1200,
    total: 1220009,
    commission: 3860,
  },
  {
    id: 4,
    imgUri:
      "https://images.jdmagicbox.com/comp/hyderabad/i4/040pxx40.xx40.171013211011.y2i4/catalogue/capital-multicuisine-restaurant-malakpet-hyderabad-home-delivery-restaurants-9e7wi.jpg?clr=#661400",

    merchantName: "ABC Restaurant",
    orderCount: 1200,
    total: 1220009,
    commission: 3860,
  },
];
