import React, { useState, useEffect } from "react";
import Overlay from "./Overlay";
import { useSnackbarStore } from "../StateManagement";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Accessing alert snackbar data from global state
  const setShowSnackbar = useSnackbarStore((state) => state.setShowSnackbar);
  const setSnackbarMessage = useSnackbarStore(
    (state) => state.setSnackbarMessage
  );
  const setSnackbarType = useSnackbarStore((state) => state.setSnackbarType);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowSnackbar(true);
      setSnackbarType("success");
      setSnackbarMessage("You're back online");
    };
    const handleOffline = () => {
      setIsOnline(false);
      setShowSnackbar(true);
      setSnackbarType("error");
      setSnackbarMessage("You're currently offline");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Polling mechanism
    const intervalId = setInterval(() => {
      if (navigator.onLine !== isOnline) {
        setIsOnline(navigator.onLine);
        if (!navigator.onLine) {
          setShowSnackbar(true);
          setSnackbarType("error");
          setSnackbarMessage("You're currently offline");
        }
      }
    }, 5000); // check every 5 seconds

    // Cleanup
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearInterval(intervalId);
      //   setShowSnackbar(false);
    };
  }, [isOnline]);

  return <Overlay showOverlay={!isOnline} />;
};

export default NetworkStatus;
