import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/bootstrap.css";
import "Components/UI/app.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "Components/UI/themes";
import { CssBaseline } from "@mui/material";
import ScrollTop from "Components/ScrollTop";
import ThemeRoutes from "Routes";
import ToastAlert from "Components/Assets/ReusableComp/ToastAlert";
import NetworkStatus from "Components/Assets/ReusableComp/NetworkStatus";
import NoteBeforeReloadForNestedLogin from "Components/Assets/ReusableComp/NoteBeforeReloadForNestedLogin";
import { useEffect, useState } from "react";
import { deleteAllCookies, getCookie } from "Components/Assets/UIServices";

function App() {
  const [isNotesOpen, setIsNotesOpen] = useState(false);

  const handleBeforeUnload = (event) => {
    if (getCookie("nestedLogin") === "true") {
      event.preventDefault();
      setIsNotesOpen(true); // Show the warning modal
      event.stop();
      event.returnValue = ""; // For legacy browsers
      return "";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleConfirmLeave = async () => {
    await window.removeEventListener("beforeunload", handleBeforeUnload);
    await deleteAllCookies();
    await window.location.reload(); // Force reload after confirmation
    await setIsNotesOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <ScrollTop>
          <ThemeRoutes />
        </ScrollTop>
      </div>
      <ToastAlert />
      <NetworkStatus />
      <NoteBeforeReloadForNestedLogin
        isNotesOpen={isNotesOpen}
        setIsNotesOpen={setIsNotesOpen}
        onConfirmLeave={handleConfirmLeave}
      />
    </ThemeProvider>
  );
}

export default App;
