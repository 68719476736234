import { lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "ErrorHandler";
// project import
import Loadable from "Components/Loadable";
import MainLayout from "Layout/MainLayout";

// render - navigations
const Dashboard = Loadable(
  lazy(() => import("Components/Navigations/Dashboard"))
);

const DeliveryPartner = Loadable(
  lazy(() => import("Components/Navigations/Settings/DeliveryPartner"))
);

const Orders = Loadable(lazy(() => import("Components/Navigations/Orders")));

const Merchants = Loadable(
  lazy(() => import("Components/Navigations/Merchants"))
);

const MerchantCatalogs = Loadable(
  lazy(() => import("Components/Navigations/Catalogs"))
);
const MerchantProducts = Loadable(
  lazy(() => import("Components/Navigations/Catalogs/Products"))
);

const MerchantLabel = Loadable(
  lazy(() => import("Components/Navigations/Catalogs/Label"))
);

const Customers = Loadable(
  lazy(() => import("Components/Navigations/Customers"))
);

const Analytics = Loadable(
  lazy(() => import("Components/Navigations/Analytics"))
);

const BusinessAnalytics = Loadable(
  lazy(() => import("Components/Navigations/Analytics/Business"))
);
const BusinessReports = Loadable(
  lazy(() => import("Components/Navigations/Analytics/Reports"))
);
const BusinessReviews = Loadable(
  lazy(() => import("Components/Navigations/Analytics/Review"))
);

const Merchant = Loadable(
  lazy(() => import("Components/Navigations/Merchants/Merchant"))
);
const Category = Loadable(
  lazy(() => import("Components/Navigations/Merchants/Category"))
);
const AdminProducts = Loadable(
  lazy(() => import("Components/Navigations/Merchants/Products"))
);
const Settings = Loadable(
  lazy(() => import("Components/Navigations/Settings"))
);
const UserPermissionSetting = Loadable(
  lazy(() => import("Components/Navigations/Settings/UserPermission"))
);
const GeneralSetting = Loadable(
  lazy(() => import("Components/Navigations/Settings/General"))
);
const OrderSetting = Loadable(
  lazy(() => import("Components/Navigations/Settings/Order"))
);
const NotificationSetting = Loadable(
  lazy(() => import("Components/Navigations/Settings/Notification"))
);

const ServiceableZones = Loadable(
  lazy(() => import("Components/Navigations/Settings/ServiceableZones"))
);

const Promotions = Loadable(
  lazy(() => import("Components/Navigations/Promotions"))
);
const Banners = Loadable(
  lazy(() => import("Components/Navigations/Promotions/Banners"))
);
const Coupons = Loadable(
  lazy(() => import("Components/Navigations/Promotions/Coupons"))
);

const MerchantCoupons = Loadable(
  lazy(() =>
    import("Components/Navigations/Promotions/Coupons/MerchantCoupons")
  )
);
const Popups = Loadable(
  lazy(() => import("Components/Navigations/Promotions/Popups"))
);

const FaultyComponent = Loadable(
  lazy(() => import("Components/Assets/FaultyComponent"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "faultycomp",
      element: <FaultyComponent />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "orders",
      element: <Orders />,
    },
    {
      path: "analytics",
      element: <Analytics />,
      children: [
        {
          path: "business",
          element: <BusinessAnalytics />,
        },
        {
          path: "reports",
          element: <BusinessReports />,
        },
        {
          path: "review",
          element: <BusinessReviews />,
        },
      ],
    },
    {
      path: "catalogs",
      element: <MerchantCatalogs />,
      children: [
        {
          path: "restaurant",
          element: <MerchantProducts />,
        },
        {
          path: "market",
          element: <MerchantProducts />,
        },
        {
          path: "category",
          element: <Category />,
        },
        {
          path: "label",
          element: <MerchantLabel />,
        },
      ],
    },
    {
      path: "merchants",
      element: <Merchants />,
      children: [
        {
          path: "",
          element: <Merchant />,
        },
        {
          path: "restaurant",
          element: <AdminProducts />,
        },
        {
          path: "market",
          element: <AdminProducts />,
        },
        {
          path: "category",
          element: <Category />,
        },
      ],
    },
    {
      path: "coupons",
      element: <MerchantCoupons />,
    },
    {
      path: "promotions",
      element: <Promotions />,
      children: [
        {
          path: "banners",
          element: <Banners />,
        },
        {
          path: "coupons",
          element: <Coupons />,
        },
        {
          path: "popups",
          element: <Popups />,
        },
      ],
    },
    {
      path: "settings",
      element: <Settings />,
      children: [
        {
          path: "permission",
          element: <UserPermissionSetting />,
        },
        {
          path: "general",
          element: <GeneralSetting />,
        },
        {
          path: "order",
          element: <OrderSetting />,
        },
        {
          path: "delivery",
          element: <DeliveryPartner />,
        },

        {
          path: "serviceable-zone",
          element: <ServiceableZones />,
        },
      ],
    },
    {
      path: "customers",
      element: <Customers />,
    },
  ],
};

export default MainRoutes;
