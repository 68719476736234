import { Box, Button, Modal, Typography } from "@mui/material";
import { ModalStyle } from "Components/UI/GlobalStyles";
import React from "react";
import { TiWarning } from "react-icons/ti";

function NoteBeforeReloadForNestedLogin({
  isNotesOpen,
  setIsNotesOpen,
  onConfirmLeave,
}) {
  return (
    <Modal open={isNotesOpen}>
      <Box sx={ModalStyle}>
        {/* Modal header with warning icon and title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <TiWarning size={32} color="orange" />
          <Typography variant="h6" component="h2">
            &nbsp;Warning: Unsaved Changes
          </Typography>
        </Box>

        {/* Horizontal line for visual separation */}
        <hr />

        <Typography variant="body2" sx={{ py: 2 }}>
          <div style={{ whiteSpace: "wrap", marginBottom: "1em" }}>
            ⚠️ You have unsaved changes. If you reload or leave this page, you
            will be logged out and may lose your unsaved data.{" "}
            <strong>
              Navigate through the app to prevent unexpected behavior.
            </strong>
          </div>
          <div style={{ whiteSpace: "wrap", marginBottom: "1em" }}>
            ✔️ Clicking "Okay" will log you out and reload the page.
          </div>
          <div style={{ whiteSpace: "wrap" }}>
            ❌ Clicking "Cancel" will keep you on the same page. Navigate
            through the app to prevent unexpected behavior.
          </div>
        </Typography>

        {/* Horizontal line for visual separation */}
        <hr />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            color="primary"
            onClick={onConfirmLeave}
          >
            Okay
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsNotesOpen(false)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default NoteBeforeReloadForNestedLogin;
