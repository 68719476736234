import React, { useState, useRef, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Modal,
  Slider,
  Typography,
  Grid,
  Skeleton,
  CardMedia,
} from "@mui/material";
import AddImage from "Components/UI/Images/AddImage.svg";
import { getCroppedImg, getRotatedImage, readFile } from "./canvasUtils";
import { getOrientation } from "get-orientation/browser";
import { MdClose } from "react-icons/md";
import Cropper from "react-easy-crop";
import {
  FullScreenModalContainer,
  FullScreenModalContent,
  FullScreenModalHeader,
  cropContainer,
  controls,
  slider,
  sliderContainer,
  sliderLabel,
} from "Components/UI/GlobalStyles";
import SaveCancelButtons from "../SaveCancelButtons";
import axios from "axios";
import { useSnackbarStore } from "Components/Assets/StateManagement";

const ImageUpload = ({ image, setImage, type }) => {
  // Accessing alert snackbar data from global state
  const setShowSnackbar = useSnackbarStore((state) => state.setShowSnackbar);
  const setSnackbarMessage = useSnackbarStore(
    (state) => state.setSnackbarMessage
  );
  const setSnackbarType = useSnackbarStore((state) => state.setSnackbarType);

  const [canvasImage, setCanvasImage] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const inputRef = useRef(null);

  useEffect(() => {
    if (imageSrc) {
      setCanvasImage(imageSrc);
      // setIsModalOpen(true);
    }
  }, [imageSrc]);

  useEffect(() => {
    setCanvasImage(image);
    setImageSrc(image);
  }, [image]);

  useEffect(() => {
    if (type.toLowerCase() === "edit") setIsModalOpen(false);
    if (type.toLowerCase() === "add") setIsModalOpen(true);
    if (canvasImage === null) setIsModalOpen(true);
  }, [imageSrc, type]);

  useEffect(() => {
    if (image !== null || image !== undefined) {
      if (type.toLowerCase() === "edit") {
        // Call the modified getBase64Image function
        getBase64Image(image)
          .then((base64Image) => {
            setCanvasImage(base64Image);
            setImageSrc(base64Image);
            setImage(base64Image);
            setIsModalOpen(false);
            setIsLoaded(true);
          })
          .catch((error) => {
            console.error("Error converting image to Base64:", error);
          });
      }
    }
  }, [image, type]);

  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.onload = () => {
          let newWidth = 200;
          let newHeight = 200;
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
          setCanvasImage(canvas.toDataURL("image/jpeg"));
        };
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null);

  // Modified getBase64Image function
  const getBase64Image = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });

      const blob = response.data;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          };
          img.onerror = () => {
            reject(new Error("Failed to load image"));
          };
        };
        reader.onerror = () => {
          reject(new Error("Failed to read the blob as a data URL"));
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error in getBase64Image:", error);
      throw error;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    onFileChange(file);
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const ORIENTATION_TO_ANGLE = {
    3: 180,
    6: 90,
    8: -90,
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      await setCanvasImage(croppedImage);
      await setIsModalOpen(false);
      await setImage(croppedImage);
      await setIsLoaded(true);
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    setCanvasImage(null);
    setImageSrc(null);
    setIsLoaded(true);
  };

  const onFileChange = async (file) => {
    const fileSizeLimit = 1 * 1024 * 1024; // 1 MB in bytes
    if (file && file.size > fileSizeLimit) {
      setShowSnackbar(true);
      setSnackbarMessage("File size exceeds 1 MB limit.");
      setSnackbarType("error");
    } else {
      let imageDataUrl = await readFile(file);

      try {
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn("Failed to detect the orientation");
      }

      setImageSrc(imageDataUrl);
      setIsModalOpen(false);
    }
  };

  const runOnClose = async () => {
    await setIsModalOpen(!isModalOpen);
    await setImage(null);
    await setCanvasImage(null);
    await setImageSrc(null);
  };

  return (
    <Box>
      {imageSrc ? (
        <>
          {!isLoaded && (
            <CardMedia width={200} height={200}>
              <Skeleton
                width={200}
                height={200}
                animation="wave"
                variant="rectangular"
              />
            </CardMedia>
          )}
          {isLoaded && (
            <Badge
              onClick={() => onClose()}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              size="large"
              badgeContent={
                <Box
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: 20,
                    height: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 50,
                  }}
                >
                  <MdClose />
                </Box>
              }
            >
              <CardMedia
                width={200}
                height={200}
                component="img"
                ref={imgRef}
                src={canvasImage}
                alt=""
                style={{
                  // maxWidth: "200px",
                  // maxHeight: "200px",
                  borderRadius: "5px",
                  border: "1px solid var(--image-upload)",
                  display: isLoaded ? "block" : "none",
                }}
              />
            </Badge>
          )}

          <Modal open={isModalOpen} sx={FullScreenModalContainer}>
            <Box>
              <Box sx={FullScreenModalHeader}>
                <Typography variant="h6">Crop Your image</Typography>
              </Box>

              <Box sx={FullScreenModalContent}>
                <Box sx={cropContainer}>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </Box>
                <Box sx={controls}>
                  <Box sx={sliderContainer}>
                    <Typography variant="overline" sx={sliderLabel}>
                      Zoom
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      sx={slider}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </Box>
                  <Box sx={sliderContainer}>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      aria-labelledby="Rotation"
                      sx={slider}
                      onChange={(e, rotation) => setRotation(rotation)}
                    />
                    <Typography variant="overline" sx={sliderLabel}>
                      Twist
                    </Typography>
                  </Box>
                </Box>

                <SaveCancelButtons
                  runOnClose={runOnClose}
                  runOnSave={showCroppedImage}
                />
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <CardMedia width={200} height={200}>
            <Grid
              onClick={handleImageClick}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid var(--image-upload)",
                borderRadius: "5px",
              }}
            >
              <Grid item>
                <img
                  src={AddImage}
                  alt="Add New"
                  style={{ width: 100, height: 100 }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ color: "var(--image-upload)" }}>
                  Drag and drop
                </Typography>
                <Typography sx={{ color: "var(--image-upload)" }}>
                  Or
                </Typography>
                <Button sx={{ color: "var(--image-upload)" }}>
                  <b>Browse</b>
                </Button>
              </Grid>
            </Grid>
          </CardMedia>
        </>
      )}
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={(e) => onFileChange(e.target.files[0])}
        // onChange={(e) => handleFileChange(e)}
        style={{ display: "none" }}
      />
    </Box>
  );
};

export default ImageUpload;
