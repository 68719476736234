import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import {
  MdMenu,
  MdClose,
  MdSearch,
  MdAccountCircle,
  MdLogout,
} from "react-icons/md";
import {
  CustomSwitch,
  DrawerHeader,
  StyledBadge,
} from "Components/UI/GlobalStyles";
import {
  useAccountStore,
  useNavigationStore,
  useEditProfileStore,
  useSnackbarStore,
  useCookies,
} from "Components/Assets/StateManagement";
import {
  Avatar,
  Badge,
  CircularProgress,
  Fade,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import Edit from "Components/Navigations/EditProfile/Edit";
import { theme } from "Components/UI/themes";
import {
  deleteAllCookies,
  deleteCookie,
  getCookie,
  setCookie,
  setCookies,
} from "Components/Assets/UIServices";
import axios from "axios";
import { useLocation } from "react-router-dom";
import EditBanner from "Components/Navigations/EditProfile/EditBanner";
import { BsFillImageFill } from "react-icons/bs";
import { useState } from "react";

import LOGO from "Components/UI/Images/Logo.png";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: `var(--header-nav-background)`,
  height: "auto",
}));

const ProfileButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("md")]: { borderRadius: "15px" },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function TopNav() {
  const setDrawerOpen = useNavigationStore((state) => state.setDrawerOpen);
  const isDrawerOpen = useNavigationStore((state) => state.isDrawerOpen);
  const isAcceptingOrders = useNavigationStore(
    (state) => state.isAcceptingOrders
  );
  const setAcceptingOrders = useNavigationStore(
    (state) => state.setAcceptingOrders
  );
  const userData = useAccountStore((state) => state.userData);
  const setUserData = useAccountStore((state) => state.setUserData);

  const setIsEditProfile = useEditProfileStore(
    (state) => state.setIsEditProfile
  );
  const setIsEditProfileBanner = useEditProfileStore(
    (state) => state.setIsEditProfileBanner
  );
  //For profile menu
  const [bannerImage, setBannerImage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    // Calculate color brightness (simple luminance formula)
    const brightness =
      0.299 * parseInt(color.substr(1, 2), 16) +
      0.587 * parseInt(color.substr(3, 2), 16) +
      0.114 * parseInt(color.substr(5, 2), 16);

    return {
      bgColor: color,
      textColor: brightness > 128 ? "#000000" : "#FFFFFF", // Choose white for light colors, black for dark colors
    };
  }
  const M = process.env.REACT_APP_LOW_LEVEL;

  const getProfileUrl =
    process.env.REACT_APP_BASE_URL_TEST_BACKEND +
    process.env.REACT_APP_USER_DETAILS_URL;

  const getProfileBannerUrl =
    process.env.REACT_APP_BASE_URL_TEST_BACKEND +
    process.env.REACT_APP_EDIT_PROFILE_BANNER;

  const setMerchantOnlineStatusUrl =
    process.env.REACT_APP_BASE_URL_TEST_BACKEND +
    process.env.REACT_APP_IS_MERCHANT_ONLINE;

  const API_KEY = process.env.REACT_APP_API_KEY;

  const setShowSnackbar = useSnackbarStore((state) => state.setShowSnackbar);
  const setSnackbarMessage = useSnackbarStore(
    (state) => state.setSnackbarMessage
  );
  const setSnackbarType = useSnackbarStore((state) => state.setSnackbarType);

  const userCookies = useCookies((state) => state.userCookies);

  const updateProfile = async () => {
    await setSnackbarMessage("Please wait while we fetch your details");
    await setSnackbarType("info");
    await setShowSnackbar(true);

    try {
      const requestBody = new URLSearchParams();
      requestBody.append("identifier", getCookie("email"));

      const requestHeader = {
        "X-API-Key": API_KEY,
      };

      // Call authentication API to get token
      const resp = await axios.post(getProfileUrl, requestBody, {
        headers: requestHeader,
        // withCredentials: true,
      });
      const respData = resp.data.data;
      if (!resp.error) {
        setCookie("username", respData.username, 1);
        setCookie("firstName", respData.profile.firstName, 1);
        setCookie("lastName", respData.profile.lastName, 1);
        setUserData(true);
      }

      await setAnchorEl(null);
      await setIsEditProfile();
    } catch (error) {
      console.log(error);
      await setSnackbarMessage("Error fetching details !! Please try again.");
      await setSnackbarType("error");
      await setShowSnackbar(true);
    }
  };

  const updateProfileBanner = async () => {
    await setSnackbarMessage("Please wait while we fetch your banner image");
    await setSnackbarType("info");
    await setShowSnackbar(true);

    try {
      const requestHeader = {
        "X-API-Key": API_KEY,
      };

      const resp = await axios.get(
        `${getProfileBannerUrl}/${getCookie("uid")}`,
        {
          headers: requestHeader,
        }
      );
      if (!resp.data.error) {
        if (resp.data.data.length !== 0) {
          // const url = resp.data.data.replace("merchantBanners/", "");
          const url = resp.data.data;
          await setBannerImage(url);
        }
      }
      await setAnchorEl(null);
      await setIsEditProfileBanner();
    } catch (error) {
      console.log(error);
      await setSnackbarMessage("Error fetching details !! Please try again.");
      await setSnackbarType("error");
      await setShowSnackbar(true);
    }
  };

  const updateAcceptingOrders = async () => {
    setIsUpdating(true);
    const updatedStatus = isAcceptingOrders === "true" ? "false" : "true";
    try {
      const requestBody = {
        id: getCookie("uid"),
        isOnline: updatedStatus === "true",
      };
      const requestHeader = {
        "X-API-Key": API_KEY,
      };
      const resp = await axios.put(
        `${setMerchantOnlineStatusUrl}`,
        requestBody,
        {
          headers: requestHeader,
        }
      );
      if (!resp.data.error) {
        setCookie("isOnline", updatedStatus);
        setAcceptingOrders(getCookie("isOnline"));
        await setSnackbarMessage(resp.data.message);
        await setSnackbarType("success");
        await setShowSnackbar(true);
      }
    } catch (error) {
      console.log(error);
      await setSnackbarMessage(error.response.data.message);
      await setSnackbarType("error");
      await setShowSnackbar(true);
    }
    setIsUpdating(false);
  };

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (getCookie("role") !== M && matches) {
      setDrawerOpen();
    }
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <AppBar position="fixed" id="appBar">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={
                getCookie("role") === M && matches ? null : setDrawerOpen
              }
            >
              {getCookie("role") === M && matches ? (
                <MdMenu />
              ) : isDrawerOpen ? (
                <MdClose />
              ) : (
                <MdMenu />
              )}
            </IconButton>
            <Box
              component="img"
              src={LOGO}
              alt="Logo"
              sx={{
                height: { xs: 25, sm: 34, md: 40 },
                // width: { xs: 24, sm: 32, md: 40 },
                width: "auto",
                display: { xs: "block", sm: "block" },
              }}
            />
            <Box sx={{ flexGrow: 1 }} />
            {getCookie("role") === M ? (
              <>
                <Typography
                  variant="body1"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  Accepting orders
                </Typography>
                {isUpdating ? (
                  <CircularProgress
                    sx={{ color: "var(--circular-progress)" }}
                  />
                ) : (
                  <CustomSwitch
                    checked={isAcceptingOrders === "true"}
                    onChange={() => updateAcceptingOrders()}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                )}
              </>
            ) : null}
            <Box
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={() => true}
            >
              <ProfileButton
                size="large"
                edge="end"
                aria-label="account of current user"
                color="inherit"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => {
                  setAnchorEl((prevAnchorEl) =>
                    prevAnchorEl === null ? e.currentTarget : null
                  );
                }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  type="Online"
                >
                  <Avatar
                    sx={{
                      bgcolor: stringToColor(
                        getCookie("firstName") + getCookie("lastName")
                      ).bgColor,
                      color: stringToColor(
                        getCookie("firstName") + getCookie("lastName")
                      ).textColor,
                    }}
                  >
                    {`${getCookie("firstName")[0]}${getCookie("lastName")[0]}`}
                  </Avatar>
                </StyledBadge>

                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography sx={{ marginLeft: "10px" }}>
                    {getCookie("firstName") + " " + getCookie("lastName")}
                  </Typography>
                </Box>
              </ProfileButton>
              <Menu
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                onClose={() => setAnchorEl(null)}
                TransitionComponent={Fade}
                anchorEl={anchorEl}
                open={open}
                PaperProps={{
                  elevation: 2,
                  style: {
                    backgroundColor: theme.palette.grey[400],
                  },
                }}
              >
                <MenuItem onClick={() => updateProfile()}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <MdAccountCircle style={{ marginRight: "8px" }} />
                    Edit Profile
                  </Box>
                </MenuItem>
                {getCookie("role") === M ? (
                  <MenuItem onClick={() => updateProfileBanner()}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <BsFillImageFill style={{ marginRight: "8px" }} />
                      Upload Banner
                    </Box>
                  </MenuItem>
                ) : null}

                {getCookie("nestedLogin") !== "false" ? (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setUserData("", false);
                      setCookies(userCookies);
                      setCookie("nestedLogin", false, 1);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MdLogout style={{ marginRight: "8px" }} />
                      Switch to Admin
                    </Box>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      deleteAllCookies();
                      setAnchorEl(null);
                      setUserData("", false);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MdLogout style={{ marginRight: "8px" }} />
                      Logout
                    </Box>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Edit Profile */}
        <Edit />
        <EditBanner image={bannerImage} />
      </Box>
    </>
  );
}
